<template>
  <div>
    <form class="form-box">
      <b-container class="container-box">
        <b-row class="no-gutters">
          <b-col sm="6" class="text-center text-sm-left">
            <h1 class="font-weight-bold text-uppercase">FILE MANAGER</h1>
          </b-col>
          <b-col sm="6" class="text-center">
            <div
              class="d-flex justify-content-center justify-content-sm-end mt-2 mt-sm-0"
            >
              <h4 class="font-weight-bold mr-2 mt-1">Status</h4>
              <!-- <InputSelect
                name="complaintStatus"
                v-model="form.document.documentStatusId"
                v-bind:options="complaintStatusList"
                valueField="id"
                textField="name"
                @onDataChange="handleChangeComplaintStatus"
                class="status-select"
              /> -->

              <b-form-select
                v-model="form.document.documentStatusId"
                :class="['mb-1 statusSelect font-weight-bold f-16']"
                ref="test"
                @change="handleChangeComplaintStatus"
                :style="{ color: colorSelect }"
              >
                <b-form-select-option
                  :value="item.id"
                  v-for="item in complaintStatusList"
                  v-bind:key="item.id"
                  :class="'status-' + item.id"
                  :style="{ color: item.color }"
                  >{{ item.name }}</b-form-select-option
                >
              </b-form-select>
            </div>
          </b-col>
        </b-row>
        <b-row
          class="no-gutters bg-white-border mt-4"
          v-bind:class="$isLoading ? 'd-none' : 'd-block'"
        >
          <b-col class="px-4 px-sm-5 py-4">
            <b-row>
              <b-col md="6">
                <div class="form-group display-custom">
                  <label class="font-weight-bold mb-1">Customer Name</label>
                  <div class="my-1">
                    <p class="form-control-static">
                      {{ form.document.customerName }}
                    </p>
                  </div>
                </div>
              </b-col>
              <b-col md="6">
                <div class="form-group display-custom">
                  <label class="font-weight-bold mb-1">ID Card</label>
                  <div class="my-1">
                    <p class="form-control-static">
                      {{ form.document.idCard }}
                    </p>
                  </div>
                </div>
              </b-col>
            </b-row>

            <b-row>
              <b-col md="6">
                <div class="form-group display-custom">
                  <label class="font-weight-bold mb-1">Line Name</label>
                  <div class="my-1">
                    <p class="form-control-static">
                      {{ form.document.lineName }}
                    </p>
                  </div>
                </div>
              </b-col>
            </b-row>

            <b-row>
              <b-col md="6">
                <div class="form-group display-custom">
                  <label class="font-weight-bold mb-1">Transaction Date</label>
                  <div class="my-1">
                    <p class="form-control-static">
                      {{ form.document.createdTime | moment($formatDateTime) }}
                    </p>
                  </div>
                </div>
              </b-col>
              <b-col md="6">
                <div class="form-group display-custom">
                  <label class="font-weight-bold mb-1">IP Address</label>
                  <div class="my-1">
                    <p class="form-control-static">
                      {{ form.document.ipAddress }}
                    </p>
                  </div>
                </div>
              </b-col>
            </b-row>

            <b-row>
              <b-col>
                <div class="form-group display-custom">
                  <label class="font-weight-bold mb-1">Details</label>
                  <div class="my-1">
                    <p class="form-control-static">
                      {{ form.document.detail }}
                    </p>
                  </div>
                </div>
              </b-col>
            </b-row>

            <div class="text-right">
              <button
                type="button"
                class="btn btn-main-2 button"
                @click="downloadFile"
                v-if="form.files.length != 0"
              >
                Download
              </button>
            </div>

            <div class="mt-5">
              <b-table
                responsive
                class="text-center"
                striped
                :fields="filesField"
                :items="form.files"
                :busy="isBusy"
                show-empty
                empty-text="No matching records found"
              >
                <template v-slot:cell(isPreview)="data">
                  <div
                    class="img-doc"
                    v-bind:style="{
                      'background-image': 'url(' + data.item.url + ')',
                    }"
                    v-if="
                      data.item.url.includes('jpg') ||
                      data.item.url.includes('png') ||
                      data.item.url.includes('jpeg')
                    "
                  ></div>
                  <div v-else-if="data.item.url.includes('pdf')">
                    <img
                      src="@/assets/images/icons/pdf.png"
                      alt
                      class="icon-file"
                    />
                  </div>
                  <div v-else-if="data.item.url.includes('tiff')">
                    <img
                      src="@/assets/images/icons/tiff.svg"
                      alt
                      class="icon-file"
                    />
                  </div>
                  <div v-else-if="data.item.url.includes('heic')">
                    <img
                      src="@/assets/images/icons/heic.png"
                      alt
                      class="icon-file"
                    />
                  </div>
                  <div v-else-if="data.item.url.includes('heif')">
                    <img
                      src="@/assets/images/icons/heif.png"
                      alt
                      class="icon-file"
                    />
                  </div>
                  <div v-else-if="data.item.url.includes('nef')">
                    <img
                      src="@/assets/images/icons/nef.png"
                      alt
                      class="icon-file"
                    />
                  </div>
                </template>
                <template v-slot:cell(id)="data">
                  <b-button variant="link" class="px-1 py-0">
                    <font-awesome-icon
                      icon="trash-alt"
                      class="text-danger"
                      @click="deleteItem(data.item.id, data.index)"
                    />
                  </b-button>
                </template>
              </b-table>
            </div>

            <div class="mt-5" v-if="form.removeLog.length != 0">
              <label
                class="font-weight-bold mb-3"
                v-if="form.removeLog.length != 0"
                >Remove Log</label
              >
              <p v-for="(item, index) in form.removeLog" :key="index">
                - ลบไฟล์ {{ item.documentName }} โดย {{ item.createdBy }}
              </p>
            </div>

            <div class="mt-5">
              <b-button v-b-toggle.collapse-1 class="btn-collapse">
                <font-awesome-icon icon="chevron-right" class="icon" />
                <font-awesome-icon icon="chevron-down" class="icon" />
                <span class="template-color-green pl-1">Status Log</span>
              </b-button>
              <b-collapse id="collapse-1" class="mt-2">
                <b-card>
                  <b-table
                    responsive
                    class="text-center"
                    striped
                    :fields="statusField"
                    :items="form.statusLog"
                    :busy="isBusy"
                    show-empty
                    empty-text="No matching records found"
                  >
                    <template v-slot:cell(updatedTime)="data">
                      <div v-if="data.item.updatedTime == null">-</div>
                      <div v-else>
                        <span>
                          {{ data.item.updatedTime | moment($formatDateTime) }}
                        </span>
                      </div>
                    </template>
                    <template v-slot:cell(note)="data">
                      <div
                        v-if="data.item.note == null || data.item.note == ''"
                      >
                        -
                      </div>
                      <div v-else>{{ data.item.note }}</div>
                    </template>
                  </b-table>
                </b-card>
              </b-collapse>
            </div>

            <b-row class="mt-5">
              <b-col md="6">
                <b-button
                  to="/document"
                  class="btn-details-set"
                  :disabled="isDisable"
                  >CANCEL</b-button
                >
              </b-col>
              <b-col md="6" class="text-sm-right">
                <button
                  v-if="id != 0"
                  type="button"
                  class="btn btn-success btn-details-set ml-md-2 text-uppercase"
                  @click="saveForm(0)"
                  :disabled="isDisable"
                >
                  Save
                </button>
                <button
                  type="button"
                  class="btn btn-success btn-details-set ml-md-2 text-uppercase"
                  @click="saveForm(1)"
                  :disabled="isDisable"
                >
                  Save & Exit
                </button>
              </b-col>
            </b-row>
          </b-col>
        </b-row>
      </b-container>
    </form>
    <!-- Modal Result -->
    <ModalAlert ref="modalAlert" :text="modalMessage" />
    <ModalAlertError ref="modalAlertError" :text="modalMessage" />

    <b-modal ref="loadingModal" hide-header hide-footer centered>
      <div class="text-center">
        <img
          src="@/assets/images/icons/loading.svg"
          alt="loading"
          class="mb-3 w-25"
        />
        <h1 class="font-weight-bold text-modal">
          In progress. Exporting Data...
        </h1>
      </div>
    </b-modal>
  </div>
</template>

<script>
import InputSelect from "@/components/inputs/InputSelect";
import ModalAlert from "@/components/modal/alert/ModalAlert";
import ModalAlertError from "@/components/modal/alert/ModalAlertError";
import axios from "axios";
import VueCookies from "vue-cookies";
import * as moment from "moment/moment";

export default {
  name: "PaymentChannel",
  components: {
    InputSelect,
    ModalAlert,
    ModalAlertError,
  },
  data() {
    return {
      id: this.$route.params.id,
      showPreview: "",
      colorSelect: "",
      isDisable: false,
      isBusy: false,
      modalMessage: "",
      items: [],
      filesField: [
        { key: "isPreview", label: "Preview" },
        { key: "url", label: "File Name", tdClass: "w-50 m-auto text-left" },
        { key: "id", label: "Action", class: "w-100px text-nowrap" },
      ],
      statusField: [
        { key: "oldStatus", label: "Old Status" },
        { key: "newStatus", label: "New Status", class: "w-100px text-nowrap" },
        { key: "updatedBy", label: "Update By", class: "w-100px text-nowrap" },
        {
          key: "updatedTime",
          label: "Update Time",
          class: "w-100px text-nowrap",
        },
      ],
      removeField: [
        {
          key: "createdTime",
          label: "Date/Time",
          class: "w-100px text-nowrap",
        },
        {
          key: "documentName",
          label: "File Name",
          class: "w-100px text-nowrap",
        },
        { key: "createdBy", label: "Update By", class: "w-100px text-nowrap" },
      ],
      selected: "a",
      options: [
        { value: "a", text: "มาใหม่" },
        { value: "b", text: "ดาวน์โหลดแล้ว" },
      ],
      complaintStatusList: [],
      form: {
        document: {
          lineName: null,
          id: 0,
          idCard: null,
          prefixName: null,
          customerName: null,
          mobileNo: null,
          lineID: null,
          attachFile: false,
          detail: null,
          documentStatusId: 0,
          ipAddress: null,
          createdTime: "0001-01-01T00:00:00",
          updatedTime: "0001-01-01T00:00:00",
          isRegistered: false,
        },
        files: [],
        removeLog: [],
        statusLog: [],
      },
      request: {
        id: this.$route.params.id,
        statusId: 0,
        removeFile: [],
      },
    };
  },
  created: async function () {
    this.$isLoading = true;
    await this.getData();
    await this.getComplaintStatus();
    this.$isLoading = false;
  },
  methods: {
    getData: async function () {
      let resData = await this.$callApi(
        "get",
        `${this.$baseUrl}/api/paymentDocument/${this.id}`,
        null,
        this.$headers,
        null
      );
      if (resData.result == 1) {
        this.form = resData.detail;
      }
    },
    handleChangeComplaintStatus: async function (value) {
      this.form.document.documentStatusId = value;

      if (this.form.document.documentStatusId == 1) {
        this.colorSelect = this.complaintStatusList[0].color;
      } else {
        this.colorSelect = this.complaintStatusList[1].color;
      }
    },
    getComplaintStatus: async function () {
      let resData = await this.$callApi(
        "get",
        `${this.$baseUrl}/api/paymentDocument/status`,
        null,
        this.$headers,
        null
      );
      if (resData.result == 1) {
        this.complaintStatusList = resData.detail;

        if (this.form.document.documentStatusId == 1) {
          this.colorSelect = this.complaintStatusList[0].color;
        } else {
          this.colorSelect = this.complaintStatusList[1].color;
        }
      }
    },
    saveForm: async function (flag) {
      this.request.id = parseInt(this.id);
      this.request.statusId = this.form.document.documentStatusId;
      this.isDisable = true;
      let resData = await this.$callApi(
        "post",
        `${this.$baseUrl}/api/paymentDocument/save`,
        null,
        this.$headers,
        this.request
      );
      this.modalMessage = resData.message;
      if (resData.result == 1) {
        this.$refs.modalAlert.show();

        if (flag == 1) {
          setTimeout(this.routeToUserList, 2000);
        } else {
          this.request.removeFile = [];
          this.$isLoading = true;
          // this.getData();
          this.$isLoading = false;
        }
      } else {
        this.$refs.modalAlertError.show();
      }
      this.isDisable = false;
    },
    deleteItem(id, index) {
      if (id != 0) this.request.removeFile.push(id);
      this.form.files.splice(index, 1);
    },
    routeToUserList() {
      this.$router.push("/document");
    },
    downloadFile: async function () {
      this.$refs.loadingModal.show();

      var config = {
        method: "get",
        url: `${this.$baseUrl}/api/paymentDocument/download/${this.id}`,
        headers: {
          "API-KEY": "SK1ADM1FND",
          Authorization: `Bearer ${VueCookies.get("token")}`,
        },
        responseType: "arraybuffer",
      };

      axios(config)
        .then((response) => {
          this.$refs.loadingModal.hide();
          var dateExcel = moment().format("DDMMYYYYhhmmss");
          const blob = new Blob([response.data], { type: "octet/stream" });
          const link = document.createElement("a");
          link.href = URL.createObjectURL(blob);
          link.download =
            `Document-Data-` +
            this.form.document.idCard +
            `-` +
            dateExcel +
            `.zip`;
          link.click();
          URL.revokeObjectURL(link.href);
        })
        .catch(function (error) {
          console.log(error);
        });
    },
  },
};
</script>

<style scoped>
.card {
  border: none;
}

.collapsed > .fa-chevron-down,
:not(.collapsed) > .fa-chevron-right {
  display: none;
}

.btn-collapse {
  border-radius: 0;
  background-color: white !important;
  font-size: 17px;
  padding: 0;
  border: 0;
}

.tab-title-collapse {
  border: none;
}

.f-16 {
  font-size: 20px;
  text-align-last: center;
  width: 300px;
}
</style>